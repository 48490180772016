<template>
  <div class="home-container">
    <Header/>
    <Location/>
    <Building/>
    <Apartments/>
    <Contact/>
  </div>
</template>

<script>
  const Header = () => import(/* webpackChunkName: "header" */ '../components/home/Header.vue');
  const Location = () => import(/* webpackChunkName: "location" */ '../components/home/Location.vue');
  const Building = () => import(/* webpackChunkName: "building" */ '../components/home/Building.vue');
  const Apartments = () => import(/* webpackChunkName: "apartments" */ '../components/home/Apartments.vue');
  const Contact = () => import(/* webpackChunkName: "contact" */ '../components/home/Contact.vue');

  export default {
    name: 'Home',
    components: {
      Header, Location, Building, Apartments, Contact
    },
    mounted() {
      this.globalEvents.$on('goToElement', (selector) => {
        if (selector !== undefined) {
          this.$vuetify.goTo(selector, {
            duration: 450,
            offset: 87,
            easing: 'easeInOutCubic',
          });
        }
      });
    }
  }
</script>

<style scoped lang="scss">
.home-container {
  overflow: hidden;
}
</style>
